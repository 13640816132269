import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

///api/itedu/v1/tiku/question/get
const getQuestion = (subjectCourseId, id) => {
  return ajax.get(`${base}/api/itedu/v1/tiku/question/get?subjectCourseId=${subjectCourseId}&id=${id}`);
};

const search = (subjectCourseId, type, query, year, province, keypointId, start, len) => {
  return ajax.get(
    `${base}/api/itedu/v1/tiku/question/search?subjectCourseId=${subjectCourseId}&type=${type}&query=${query}&year=${year}&province=${province}&keypointId=${keypointId}&start=${start}&len=${len}`
  );
};

const queryByFbPaperId = (subjectCourseId, type, fbPaperId, start, len) => {
  return ajax.get(
    `${base}/api/itedu/v1/tiku/question/by-fb-paper?subjectCourseId=${subjectCourseId}&type=${type}&fbPaperId=${fbPaperId}&start=${start}&len=${len}`
  );
};

const get = (subjectCourseId, id) => {
  return ajax.get(`${base}/api/itedu/v1/tiku/question/get?subjectCourseId=${subjectCourseId}&id=${id}`);
};

const batchGet = (subjectCourseId, ids) => {
  return ajax.get(`${base}/api/itedu/v1/tiku/question/batch-get?subjectCourseId=${subjectCourseId}&ids=${ids}`);
};

const addChoice = (params) => {
  return ajax.post(`${base}/api/itedu/v1/tiku/question/choice/add`, params);
};

const addBlank = (params) => {
  return ajax.post(`${base}/api/itedu/v1/tiku/question/blank/add`, params);
};

const cutWords = (query) => {
  return ajax.get(`${base}/api/itedu/v1/tiku/question/cut-word?query=${query}`);
};

const addMaterial = (material) => {
  return ajax.post(`${base}/api/itedu/v1/tiku/material/add-edit`, material);
};

const pagerMaterial = (subjectCourseId, start, len) => {
  return ajax.get(
    `${base}/api/itedu/v1/tiku/material/pager?subjectCourseId=${subjectCourseId}&start=${start}&len=${len}`
  );
};

const getMaterial = (subjectCourseId, id) => {
  return ajax.get(`${base}/api/itedu/v1/tiku/material/get?subjectCourseId=${subjectCourseId}&id=${id}`);
};

const addEditPaper = (paper) => {
  return ajax.post(`${base}/api/itedu/v1/tiku/paper/add-edit`, paper);
};

const getPaper = (subjectCourseId, id) => {
  return ajax.get(`${base}/api/itedu/v1/tiku/paper/get?subjectCourseId=${subjectCourseId}&id=${id}`);
};

const univSolution = (subjectCourseId, id) => {
  return ajax.get(`${base}/api/itedu/v1/tiku/paper/univ?subjectCourseId=${subjectCourseId}&id=${id}`);
};

const pagerPaper = (subjectCourseId, start, len) => {
  return ajax.get(`${base}/api/itedu/v1/tiku/paper/pager?subjectCourseId=${subjectCourseId}&start=${start}&len=${len}`);
};

const addExerciseBook = (exerciseBook) => {
  return ajax.post(`${base}/api/itedu/v1/tiku/exerciseBook/add`, exerciseBook);
};

const getExerciseBook = (subjectCourseId, id) => {
  return ajax.get(`${base}/api/itedu/v1/tiku/exerciseBook/get?subjectCourseId=${subjectCourseId}&id=${id}`);
};

const pagerExerciseBook = (subjectCourseId, start, len) => {
  return ajax.get(
    `${base}/api/itedu/v1/tiku/exerciseBook/pager?subjectCourseId=${subjectCourseId}&start=${start}&len=${len}`
  );
};

const simpleTikuGet = (subjectCourseId, id) => {
  return ajax.get(`${base}/api/itedu/v1/tiku/simple/get?subjectCourseId=${subjectCourseId}&id=${id}`);
};

const simpleTikuAddEdit = (item) => {
  return ajax.post(`${base}/api/itedu/v1/tiku/simple/add-edit`, item);
};

const simpleTikuDelete = (subjectCourseId, id) => {
  return ajax.post(`${base}/api/itedu/v1/tiku/simple/delete?subjectCourseId=${subjectCourseId}&id=${id}`);
};

const simpleTikuSearch = (subjectCourseId, tikuType, title, start, len) => {
  return ajax.get(
    `${base}/api/itedu/v1/tiku/simple/search?subjectCourseId=${subjectCourseId}&tikuType=${tikuType}&title=${title}&start=${start}&len=${len}`
  );
};

const simpleTikuCosPrefix = () => {
  return ajax.get(`${base}/api/itedu/v1/tiku/simple/cos-prefix`);
};

const simpleTikuCdnUrl = (key) => {
  return ajax.get(`${base}/api/itedu/v1/tiku/simple/cos-url?key=${key}`);
};

const allTikuSubjectCourse = () => {
  return ajax.get(`${base}/api/itedu/v1/tiku/subject-course/online`);
};

export const tikuApi = {
  getQuestion: getQuestion,
  search: search,
  queryByFbPaperId: queryByFbPaperId,
  get: get,
  batchGet: batchGet,
  addChoice: addChoice,
  addBlank: addBlank,
  cutWords: cutWords,
  addMaterial: addMaterial,
  pagerMaterial: pagerMaterial,
  getMaterial: getMaterial,
  addEditPaper: addEditPaper,
  pagerPaper: pagerPaper,
  getPaper: getPaper,
  univSolution: univSolution,
  addExerciseBook: addExerciseBook,
  getExerciseBook: getExerciseBook,
  pagerExerciseBook: pagerExerciseBook,
  simpleTikuGet: simpleTikuGet,
  simpleTikuAddEdit: simpleTikuAddEdit,
  simpleTikuSearch: simpleTikuSearch,
  simpleTikuCosPrefix: simpleTikuCosPrefix,
  simpleTikuCdnUrl: simpleTikuCdnUrl,
  simpleTikuDelete: simpleTikuDelete,
  allTikuSubjectCourse: allTikuSubjectCourse,
};
